import React from 'react';
import { Link } from '../Link';

export const SIZES = ['small', 'medium', 'large'] as const;

export type Size = typeof SIZES[number];

export const MAP = {
  small: 300,
  medium: 450,
  large: 600,
};

export const REGEX =
  /(?:(?:https?:)?\/\/)?(?:(?:www|m)\.)?(?:(?:soundcloud\.com\/))(?:[\w-]+)(\/sets\/)?(?:[\w\/-]+)?/;

export const LOCAL_STORAGE_ITEM = 'x-consent-soundCloud';

export const MOCK_EMBED = {
  single: {
    url: 'https://soundcloud.com/swedishforestbirds/scent-of-pine',
    title: 'Aaa',
  },
  multiple: {
    url: 'https://soundcloud.com/swedishforestbirds/sets/moment-of-nature',
    title: 'Aaa',
  },
};

export const TRANS = {
  consentTitle: {
    en: 'We need your consent to show you this SoundCloud player.',
    fi: 'Tarvitsemme suostumuksesi näyttääksemme tämän SoundCloud-soittimen.',
  },
  consentParagraph: {
    en: (
      <>
        SoundCloud might collect personal data and track your listening
        behavior. We’ll only load the player if you consent to their use of
        cookies and similar technologies as described in their{' '}
        <Link
          href={
            'https://pages.soundcloud.com/geo/uk_us_ie/legal/privacy-policy.html'
          }
        >
          privacy policy
        </Link>
        .
      </>
    ),
    fi: (
      <>
        SoundCloud saattaa kerätä henkilötietoja ja seurata
        kuuntelukäyttäytymistäsi. Lataamme soittimen vain, jos hyväksyt
        evästeiden ja vastaavien tekniikoiden käyttöön heidän{' '}
        <Link
          href={'https://pages.soundcloud.com/geo/fi/legal/privacy-policy.html'}
        >
          tietosuojakäytännössään
        </Link>{' '}
        kuvatulla tavalla.
      </>
    ),
  },
  consentButton: {
    en: 'Consent',
    fi: 'Hyväksy',
  },
};
