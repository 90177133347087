import { localizeDate } from './localizeDate';
import { localizeDateAndTime } from './localizeDateAndTime';

export const localizeDateMaybeTime = (
  locale: string,
  datetime: string,
  condensed?: boolean
): string => {
  /* hide time if 00:00 or 23:59 */
  const date = new Date(datetime);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const hideTime =
    (hours === 0 && minutes === 0) || (hours === 23 && minutes === 59);

  return hideTime
    ? localizeDate(locale, datetime, condensed)
    : localizeDateAndTime(locale, datetime, condensed);
};
