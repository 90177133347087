import { useLocaleContext } from '../../context/LocaleContext';
import { usePageContext } from '../../context/PageContext';
import { Locale, LocaleOrUndefined } from '../types';

export const useLocale = (
  componentLocale: LocaleOrUndefined
): { locale: Locale; lang: LocaleOrUndefined } => {
  /* get the locale of a possibly deeply nested locale context */
  const nestedLocale = useLocaleContext();

  /* get the page locale */
  const { locale: pageLocale } = usePageContext();

  /* the locale is either the component locale, or if that's undefined, the nested locale, or if that's undefined, the page locale */
  const locale = componentLocale || nestedLocale || pageLocale;

  /* the lang is NOT undefined only when we need to specify a lang attribute for the current component or element; for example, if the component locale is english but the nearest nested locale is finnish */
  const lang =
    componentLocale === undefined ||
    (nestedLocale !== undefined && componentLocale === nestedLocale) ||
    (nestedLocale === undefined && componentLocale === pageLocale)
      ? undefined
      : locale;

  return { locale, lang };
};
