import React, { ReactElement } from 'react';
import { Box, BoxProps } from '@chakra-ui/react';
import { fluid } from '../../theme/fluid';
import { isEmptyNode } from '../../utils/other';
import { SkipLink } from '../SkipLink';

export type MainProps = Omit<BoxProps, 'as'>;

export const Main = ({ children, ...rest }: MainProps): ReactElement | null =>
  isEmptyNode(children) ? null : (
    <Box
      as={'main'}
      flexGrow={1}
      marginX={fluid.fullToFull}
      marginY={fluid.fullToFixed}
      {...rest}
    >
      <SkipLink.Destination
        id={'main'}
        labels={{ en: 'Main content', fi: 'Pääsisältö' }}
      />
      {children}
    </Box>
  );
