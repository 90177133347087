import React, {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
} from 'react';
import { LocaleOrUndefined } from '../../utils/types';

export type LocaleContextProps = {
  value: LocaleOrUndefined;
  children: ReactNode;
};

const Context = createContext<LocaleOrUndefined>(undefined);

export const LocaleContext = (props: LocaleContextProps): ReactElement => (
  <Context.Provider {...props} />
);

export const useLocaleContext = (): LocaleOrUndefined => useContext(Context);
