import React, { ReactElement } from 'react';
import { ChakraComponent } from '@chakra-ui/react';
import parse, { Element } from 'html-react-parser';

export type SvgProps = {
  children: string;
  fill?: string;
  stroke?: string;
};

export const Svg = (({
  children,
  fill,
  stroke,
}: SvgProps): ReactElement | null =>
  !children
    ? /* if no children, return null */ null
    : /* otherwise, parse HTML from string */ (parse(children, {
        /* modify certain DOM nodes */
        replace: (node) => {
          if (
            node instanceof Element &&
            node.type === 'style' &&
            (fill || stroke)
          ) {
            /* if the node is a <style> element and we passed fill / stroke props, remove that node */
            return <></>;
          } else if (node instanceof Element && node.name === 'svg') {
            /* otherwise, if the node is an <svg> element... */
            /* set height and width to 100% */
            node.attribs.height = '100%';
            node.attribs.width = '100%';
            /* remove ID */
            node.attribs.id = '';
            /* if we passed a fill prop, apply it to the node */
            if (fill) {
              node.attribs.fill = fill;
            }
            /* if we passed a stroke prop, apply it to the node */
            if (stroke) {
              node.attribs.stroke = stroke;
            }
          } else if (node instanceof Element) {
            /* ...otherwise, if the node is any other element */
            /* remove ID */
            node.attribs.id = '';
            /* if we passed fill / stroke props and the node is not a <div>, remove style attribute */
            if ((fill || stroke) && node.name !== 'div') {
              node.attribs.style = '';
            }
            /* if we passed a fill prop and the current node's fill is not "none" or "transparent", apply fill */
            if (
              fill &&
              node.attribs.fill !== 'none' &&
              node.attribs.fill !== 'transparent'
            ) {
              node.attribs.fill = fill;
            }
            /* if we passed a stroke prop and the current node's stroke is not "none" or "transparent", apply stroke */
            if (
              stroke &&
              node.attribs.stroke !== 'none' &&
              node.attribs.stroke !== 'transparent'
            ) {
              node.attribs.stroke = stroke;
            }
          }
        },
        /* cast the return value as a ReactElement, and the component itself as a Chakra SVG component */
      }) as ReactElement)) as ChakraComponent<'svg'>;
