import React, { ReactElement } from 'react';
import { Text, TextProps } from '@chakra-ui/react';
import { isEmptyNode } from '../../utils/other';

export type ParagraphProps = Omit<TextProps, 'as'>;

export const Paragraph = ({
  children,
  ...rest
}: ParagraphProps): ReactElement | null =>
  isEmptyNode(children) ? null : (
    <Text as={'p'} marginY={'1em'} {...rest}>
      {children}
    </Text>
  );
