import React, { ReactElement } from 'react';
import { navigate } from 'gatsby-link';
import {
  Box,
  Collapse,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  VisuallyHidden,
} from '@chakra-ui/react';
import { FaGlobe } from 'react-icons/fa';
import { usePageContext } from '../../context/PageContext';
import { useLocale } from '../../utils/hooks';
import { Locale } from '../../utils/types';
import { Button } from '../Button';
import { TRANS } from './misc';

export type LanguageSwitcherProps = {
  componentLocale?: Locale;
};

export const LanguageSwitcher = ({
  componentLocale,
}: LanguageSwitcherProps): ReactElement => {
  const { locale, lang } = useLocale(componentLocale);
  const { translationPath } = usePageContext();

  const changeLocale = () => {
    navigate(translationPath);
  };

  return (
    <Menu placement={'bottom-end'} offset={[0, 5]}>
      {({ isOpen }) => (
        <Box lang={lang}>
          <MenuButton
            as={Button}
            borderRadius={'50%'}
            lineHeight={0}
            marginEnd={'0.5em'}
            padding={'0.35em'}
            _expanded={{ color: 'tealCarnation' }}
          >
            <FaGlobe />
            <VisuallyHidden>{TRANS.language[locale]}</VisuallyHidden>
          </MenuButton>
          <MenuList
            as={Collapse}
            animateOpacity={false}
            in={isOpen}
            backgroundColor={'tealCarnation'}
            borderColor={'tealCarnation'}
            borderRadius={0}
            borderWidth={2}
            borderTopWidth={'0.25rem'}
            borderBottomWidth={'0.25rem'}
            color={'white'}
            marginEnd={'-0.5em'}
            minWidth={'unset'}
            transform={'none !important'}
          >
            <MenuOptionGroup
              type={'radio'}
              value={locale}
              onChange={() => changeLocale()}
            >
              <MenuItemOption
                value={'en'}
                _hover={{ backgroundColor: 'white', color: 'tealCarnation' }}
                _focus={{ backgroundColor: 'white', color: 'tealCarnation' }}
              >
                <span aria-hidden>EN</span>
                <VisuallyHidden>{TRANS.english[locale]}</VisuallyHidden>
              </MenuItemOption>
              <MenuItemOption
                value={'fi'}
                _hover={{ backgroundColor: 'white', color: 'tealCarnation' }}
                _focus={{ backgroundColor: 'white', color: 'tealCarnation' }}
              >
                <span aria-hidden>FI</span>
                <VisuallyHidden>{TRANS.finnish[locale]}</VisuallyHidden>
              </MenuItemOption>
            </MenuOptionGroup>
          </MenuList>
        </Box>
      )}
    </Menu>
  );
};
