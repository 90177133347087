import {
  FaAt,
  FaFacebookF,
  FaInstagram,
  FaLink,
  FaLinkedinIn,
  FaMediumM,
  FaPhoneAlt,
  FaResearchgate,
  FaTwitter,
  FaYoutube,
} from 'react-icons/fa';

export const CONTACT_ICONS: {
  [key: string]: unknown;
} = {
  email: FaAt,
  facebook: FaFacebookF,
  instagram: FaInstagram,
  linkedIn: FaLinkedinIn,
  medium: FaMediumM,
  phone: FaPhoneAlt,
  researchGate: FaResearchgate,
  twitter: FaTwitter,
  website: FaLink,
  youTube: FaYoutube,
};

export const CONTACT_REGEX: {
  [key: string]: RegExp;
} = {
  email: /\w[\w.-]*@([\w-]+\.)+[\w-]+/,
  facebook: /(?:https?:\/\/)?(?:www\.)?facebook\.com\/(?:.+\/)*([\w\.]+)/,
  instagram: /(?:https?:\/\/)?(?:www\.)?instagram\.com\/([\w\.\_]+)/,
  linkedIn:
    /(?:https?:\/\/)?(?:[\w]+\.)?linkedin\.com\/(?:pub|in|profile)\/([\w\.\-]+)/,
  medium: /(?:https?:\/\/)?(?:[\w]+\.)?medium\.com\/([\w\.\-\@]+)/,
  phone: /(\+|00)[1-9][0-9 \-\(\)\.]{7,32}/,
  researchGate:
    /(?:https?:\/\/)?(?:[\w]+\.)?researchgate\.net\/(?:.+\/)*([\w\-]+)/,
  twitter: /(?:https?:\/\/)?(?:www\.)?twitter\.com\/(?:#!\/)?@?([^\/\?\s]*)/,
  website: /(?:https?:\/\/)?(?:www\.)?([\w\.\/]+)/,
  youTube:
    /(?:https?:\/\/)?(?:[\w]+\.)?youtube(?:\.[\w]+)+\/(?:.+\/)*([\w\_\-]+)/,
};

export const TRANS: {
  [key: string]: {
    en: string;
    fi: string;
  };
} = {
  email: {
    en: 'Email',
    fi: 'Sähköposti',
  },
  facebook: {
    en: 'Facebook',
    fi: 'Facebook',
  },
  instagram: {
    en: 'Instagram',
    fi: 'Instagram',
  },
  linkedIn: {
    en: 'LinkedIn',
    fi: 'LinkedIn',
  },
  medium: {
    en: 'Medium',
    fi: 'Medium',
  },
  phone: {
    en: 'Phone',
    fi: 'Puhelinnumero',
  },
  twitter: {
    en: 'Twitter',
    fi: 'Twitter',
  },
  researchGate: {
    en: 'ResearchGate',
    fi: 'ResearchGate',
  },
  website: {
    en: 'Website',
    fi: 'Nettisivu',
  },
  youTube: {
    en: 'YouTube',
    fi: 'YouTube',
  },
};

export const CONTACT_NAMES = Object.fromEntries(
  Object.entries(TRANS).map(([key, value]) => [value.en, key])
);
