import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import GatsbyLink from 'gatsby-link';
import {
  Box,
  Collapse,
  Flex,
  Hide,
  List,
  Popover,
  PopoverAnchor,
  PopoverContent,
  PopoverTrigger,
  Show,
  Square,
  Stack,
  useOutsideClick,
  VisuallyHidden,
  Wrap,
} from '@chakra-ui/react';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  HamburgerIcon,
  SmallCloseIcon,
} from '@chakra-ui/icons';
import { FaHome } from 'react-icons/fa';
import { Global } from '@emotion/react';
import { useResizeDetector } from 'react-resize-detector';
import throttle from 'lodash.throttle';
import { fluid, OFFSET } from '../../theme/fluid';
import { usePageContext } from '../../context/PageContext';
import { Locale } from '../../utils/types';
import { Button } from '../Button';
import { LanguageSwitcher } from '../LanguageSwitcher';
import { Link } from '../Link';
import { SkipLink } from '../SkipLink';
import { TRANS } from './misc';

type NavigationPage = {
  title: string;
  slug: string;
  subpages?: NavigationPage[];
};

const NavigationHome = (): ReactElement => {
  const { locale } = usePageContext();
  return (
    <Box as={'li'} display={'flex'}>
      <Link
        as={GatsbyLink}
        to={`/${locale}`}
        color={'white'}
        backgroundColor={'tealCarnation'}
        borderWidth={2}
        borderColor={'tealCarnation'}
        width={'min-content'}
        _hover={{ borderColor: 'white' }}
      >
        <Square size={'1.4em'}>
          <FaHome />
          <VisuallyHidden>{TRANS.frontpage[locale]}</VisuallyHidden>
        </Square>
      </Link>
    </Box>
  );
};

type NavigationNodeLinkProps = {
  to: string;
  title: string;
};

const NavigationNodeLink = ({ to, title }: NavigationNodeLinkProps) => (
  <Link
    as={GatsbyLink}
    to={to}
    partiallyActive={true}
    activeStyle={{
      textDecoration: 'underline 1px',
    }}
    color={'white'}
    padding={'0.1em'}
    _hover={{
      textDecoration: 'underline 2px !important',
    }}
  >
    {title}
  </Link>
);

type NavigationNodeButtonProps = {
  expanded: boolean;
  setExpanded: Dispatch<SetStateAction<boolean>>;
  title: string;
};

const NavigationNodeButton = ({
  expanded,
  setExpanded,
  title,
}: NavigationNodeButtonProps) => {
  const { locale } = usePageContext();

  return (
    <Button
      aria-expanded={expanded}
      onClick={() => setExpanded(!expanded)}
      backgroundColor={'transparent'}
      borderRadius={'50%'}
      borderWidth={0}
      color={'white'}
      lineHeight={0}
      marginStart={'0.2em'}
      padding={'0.05em'}
      _hover={{ backgroundColor: 'white', color: 'tealCarnation' }}
      _expanded={{ backgroundColor: 'white', color: 'tealCarnation' }}
    >
      {expanded ? (
        <ChevronUpIcon aria-hidden />
      ) : (
        <ChevronDownIcon aria-hidden />
      )}
      <VisuallyHidden>
        {TRANS.showSubmenu[locale]}: {title}
      </VisuallyHidden>
    </Button>
  );
};

type NavigationNodeProps = {
  page: NavigationPage;
  prefix: string;
  popover?: boolean;
};

const NavigationNode = ({
  page,
  prefix,
  popover,
}: NavigationNodeProps): ReactElement => {
  const { location } = usePageContext();
  const { title, slug, subpages } = page;
  const newPrefix = `${prefix}/${slug}`;
  const [expanded, setExpanded] = useState(false);
  useEffect(() => setExpanded(false), [location?.pathname]);
  const ref = useRef<HTMLDivElement>(null);
  useOutsideClick({ ref: ref, handler: () => expanded && setExpanded(false) });

  return (
    <Box as={'li'} display={'flex'} paddingEnd={'0.5em'}>
      <Box
        onBlur={({ relatedTarget }) =>
          relatedTarget &&
          !ref?.current?.contains(relatedTarget) &&
          expanded &&
          setExpanded(false)
        }
      >
        {!subpages ? (
          <NavigationNodeLink to={newPrefix} title={title} />
        ) : !popover ? (
          <Box>
            <Box>
              <NavigationNodeLink to={newPrefix} title={title} />
              <NavigationNodeButton
                expanded={expanded}
                setExpanded={setExpanded}
                title={title}
              />
            </Box>
            <Collapse in={expanded}>
              <Box
                ref={ref}
                as={List}
                borderColor={'white'}
                borderStartWidth={1}
                marginY={'0.2em'}
                marginStart={'0.2em'}
                paddingStart={{ base: '1em', s: '0.5em' }}
              >
                {subpages?.map((page) => (
                  <NavigationNode
                    key={`${newPrefix}/${page.slug}`}
                    prefix={newPrefix}
                    page={page}
                  />
                ))}
              </Box>
            </Collapse>
          </Box>
        ) : (
          <Popover
            isOpen={expanded}
            placement={'bottom-start'}
            offset={[0, 0]}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={false}
          >
            <PopoverAnchor>
              <Box>
                <NavigationNodeLink to={newPrefix} title={title} />
                <PopoverTrigger>
                  <NavigationNodeButton
                    expanded={expanded}
                    setExpanded={setExpanded}
                    title={title}
                  />
                </PopoverTrigger>
              </Box>
            </PopoverAnchor>
            <PopoverContent
              as={Box}
              variants={{
                exit: {
                  height: 0,
                  overflowY: 'hidden',
                  transition: {
                    height: { duration: 0.2, ease: [0.25, 0.1, 0.25, 1] },
                    overflowY: { duration: 0 },
                  },
                },
                enter: {
                  height: 'auto',
                  overflowY: 'auto',
                  transition: {
                    height: { duration: 0.3, ease: [0.25, 0.1, 0.25, 1] },
                    overflowY: { delay: 0.3, duration: 0 },
                  },
                },
              }}
              ref={ref}
              backgroundColor={'tealCarnation'}
              border={0}
              borderRadius={0}
              marginTop={'0.4em'}
              width={'calc(100% + 1em)'}
              sx={{
                'maxHeight': 'calc(100vh - 2em)',
                'overflowX': 'hidden',
                'scrollbarGutter': 'stable',
                'overscrollBehaviorY': 'contain',
                'scrollbarColor': 'white transparent',
                'scrollbarFaceColor': 'white',
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: 'white',
                  borderColor: 'tealCarnation',
                },
              }}
            >
              <Box padding={'0.5em'} as={List}>
                {subpages?.map((page) => (
                  <NavigationNode
                    key={`${newPrefix}/${page.slug}`}
                    prefix={newPrefix}
                    page={page}
                  />
                ))}
              </Box>
            </PopoverContent>
          </Popover>
        )}
      </Box>
    </Box>
  );
};

type NavigationTopLevelProps = {
  pages?: NavigationPage[];
};

const NavigationTopLevel = ({
  pages,
}: NavigationTopLevelProps): ReactElement => {
  const { locale, location } = usePageContext();
  const prefix = `/${locale}`;
  const [expanded, setExpanded] = useState(false);
  useEffect(() => setExpanded(false), [location?.pathname]);
  const ref = useRef<HTMLDivElement>(null);
  useOutsideClick({ ref: ref, handler: () => expanded && setExpanded(false) });

  return (
    <Box flexGrow={1}>
      <Show above={'m'}>
        <Wrap
          align={'center'}
          sx={{
            '& ul': { margin: 0 },
            'maxHeight': 'calc(100vh - 1em)',
            'overflowX': 'hidden',
            'overflowY': 'scroll',
            'overscrollBehaviorY': 'contain',
            'scrollbarColor': 'white transparent',
            'scrollbarFaceColor': 'white',
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'white',
              borderColor: 'tealCarnation',
              borderWidth: '0.25rem',
            },
          }}
        >
          <NavigationHome />
          {pages?.map((page) => (
            <NavigationNode
              key={`${prefix}/${page.slug}`}
              prefix={prefix}
              page={page}
              popover
            />
          ))}
        </Wrap>
      </Show>
      <Hide above={'m'}>
        <Box
          onBlur={({ relatedTarget }) =>
            relatedTarget &&
            !ref?.current?.contains(relatedTarget) &&
            expanded &&
            setExpanded(false)
          }
        >
          <Button
            aria-expanded={expanded}
            onClick={() => setExpanded(!expanded)}
            borderRadius={'50%'}
            lineHeight={0}
            padding={'0.3em'}
          >
            {expanded ? (
              <SmallCloseIcon aria-hidden />
            ) : (
              <HamburgerIcon aria-hidden />
            )}
            <VisuallyHidden>{TRANS.showMenu[locale]}</VisuallyHidden>
          </Button>
          <Box
            ref={ref}
            sx={{
              'marginY': expanded ? '0.5em' : 0,
              'maxHeight': 'calc(100vh - 5em)',
              'overflowX': 'hidden',
              'overflowY': 'auto',
              'overscrollBehaviorY': 'contain',
              'scrollbarColor': 'white transparent',
              'scrollbarFaceColor': 'white',
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'white',
                borderColor: 'tealCarnation',
              },
            }}
          >
            <Collapse in={expanded}>
              <Stack
                borderColor={'white'}
                borderStartWidth={1}
                marginStart={'0.9em'}
                paddingStart={'1em'}
                paddingY={'0.5em'}
              >
                <NavigationHome />
                {pages?.map((page) => (
                  <NavigationNode
                    key={`${prefix}/${page.slug}`}
                    prefix={prefix}
                    page={page}
                  />
                ))}
              </Stack>
            </Collapse>
          </Box>
        </Box>
      </Hide>
    </Box>
  );
};

export type NavigationProps = {
  data?: {
    node_locale: Locale;
    subpages: NavigationPage[];
  };
  sticky?: boolean;
};

export const Navigation = ({ data, sticky }: NavigationProps): ReactElement => {
  const { locale } = usePageContext();

  const { height, ref } = useResizeDetector({
    handleHeight: true,
    handleWidth: false,
    refreshMode: 'debounce',
    refreshRate: 0,
  });

  const [fixed, setFixed] = useState(false);

  useEffect(() => {
    if (!sticky || typeof window === 'undefined') {
      return;
    }

    const onScroll = throttle(
      () => {
        if (!fixed) {
          window.scrollY > window.innerHeight && setFixed(true);
        } else {
          window.scrollY <= window.innerHeight && setFixed(false);
        }
      },
      20,
      { leading: true, trailing: false }
    );

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  });

  return (
    <>
      <Box
        ref={ref}
        as={'nav'}
        aria-label={TRANS.primary[locale]}
        backgroundColor={'tealCarnation'}
        color={'white'}
        marginStart={-OFFSET}
        marginEnd={fluid.halfToHalf}
        paddingStart={fluid.halfToHalfOffset}
        {...(sticky && !fixed
          ? {}
          : {
              position: 'fixed',
              top: 0,
              right: 0,
              left: 0,
            })}
        zIndex={1000}
      >
        <Box paddingY={'0.5em'}>
          <Global
            styles={{ html: { scrollPaddingTop: (height || 40) + 20 } }}
          />
          <SkipLink.Origin
            id={'main'}
            labels={{ en: 'Skip to main content', fi: 'Siirry pääsisältöön' }}
            zIndex={1001}
          />
          <Flex>
            {data?.subpages && <NavigationTopLevel pages={data.subpages} />}
            <LanguageSwitcher />
          </Flex>
        </Box>
      </Box>
      {sticky && fixed && <Box height={height} />}
    </>
  );
};
