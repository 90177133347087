import React, { ReactElement } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { usePageContext } from '../../context/PageContext';
import { Navigation, NavigationProps } from '.';

export type NavigationWithQueryProps = Omit<NavigationProps, 'data'>;

type NavigationQuery = {
  allContentfulPageFrontpage: {
    edges: {
      node: NavigationProps['data'];
    }[];
  };
};

export const NavigationWithQuery = (
  props: NavigationWithQueryProps
): ReactElement => {
  const { locale } = usePageContext();
  const data: NavigationQuery = useStaticQuery(query);
  const menuData = data.allContentfulPageFrontpage.edges.find(
    (edge) => edge.node?.node_locale === locale
  )?.node;

  return <Navigation data={menuData} {...props} />;
};

const query = graphql`
  {
    allContentfulPageFrontpage {
      edges {
        node {
          node_locale
          subpages {
            ... on ContentfulEntry {
              ... on ContentfulPageBlogCollection {
                slug
                title
              }
              ... on ContentfulPageEventCollection {
                slug
                title
              }
              ... on ContentfulPageGeneric {
                slug
                title
                subpages {
                  ... on ContentfulPageGeneric {
                    slug
                    title
                    subpages {
                      ... on ContentfulPageGeneric {
                        slug
                        title
                        subpages {
                          ... on ContentfulPageGeneric {
                            slug
                            title
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
