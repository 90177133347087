import { localizeDate } from './localizeDate';
import { localizeDateAndTime } from './localizeDateAndTime';

export const formatDateTime = (
  locale: string,
  date: string,
  dateOnly?: boolean,
  condensed?: boolean
): string =>
  dateOnly
    ? localizeDate(locale, date, condensed)
    : localizeDateAndTime(locale, date, condensed);
