import React, { Children, ReactElement, ReactNode } from 'react';
import { Box } from '@chakra-ui/react';

export type SmartLineBreakProps = {
  children: ReactNode;
};

export const SmartLineBreak = ({
  children,
}: SmartLineBreakProps): ReactElement | null => {
  if (!children) {
    return null;
  }

  const array = Children.toArray(children);

  if (array.length === 1) {
    return (
      <Box as={'span'} display={'inline-block'}>
        {children}
      </Box>
    );
  }

  const half = Math.ceil(array.length / 2);

  return (
    <Box as={'span'} display={'inline-block'}>
      <SmartLineBreak>{array.slice(0, half)}</SmartLineBreak>{' '}
      <SmartLineBreak>{array.slice(half)}</SmartLineBreak>
    </Box>
  );
};
