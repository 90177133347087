export const localizeTime = (
  locale: string,
  datetime: string,
  condensed?: boolean
): string =>
  /* example output in en-GB from the options below:
  - normal: 5:
  - condensed: 31/12/2000
  */
  new Date(datetime).toLocaleTimeString(locale, {
    hour: condensed ? '2-digit' : 'numeric',
    minute: condensed ? '2-digit' : 'numeric',
    second: undefined,
    hourCycle: 'h23',
  });
