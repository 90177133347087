import React, { ReactElement } from 'react';
import { GatsbyImage, GatsbyImageProps } from 'gatsby-plugin-image';
import { Box } from '@chakra-ui/react';

export type ImageRasterProps = {
  textAlternative?: string | null;
  width?: string | number;
  maxWidth?: string | number;
} & Omit<GatsbyImageProps, 'alt'>;

export const ImageRaster = ({
  image,
  textAlternative,
  width,
  maxWidth,
  ...rest
}: ImageRasterProps): ReactElement | null =>
  !image ? null : width ? (
    <Box width={width}>
      <Box width={'100%'}>
        <GatsbyImage
          key={image.images.fallback?.src}
          alt={textAlternative || ''}
          image={image}
          loading={'eager'}
          style={{ display: 'block' }}
          {...rest}
        />
      </Box>
    </Box>
  ) : maxWidth ? (
    <Box maxWidth={maxWidth}>
      <GatsbyImage
        key={image.images.fallback?.src}
        alt={textAlternative || ''}
        image={image}
        loading={'eager'}
        style={{ display: 'block' }}
        {...rest}
      />
    </Box>
  ) : (
    <GatsbyImage
      key={image.images.fallback?.src}
      alt={textAlternative || ''}
      image={image}
      loading={'eager'}
      style={{ display: 'block' }}
      {...rest}
    />
  );
