import React, { ReactElement } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';
import { siteConfig } from '../../../siteConfig';
import { usePageContext } from '../../context/PageContext';
import { trimFinalSlash } from '../../utils/string';
import { Locale } from '../../utils/types';
import { CONTACT_REGEX } from '../Contact/misc';

export type HeadProps = {
  authors?: string[];
  hideFromSearchEngines?: boolean;
  pageImage?: string;
  pageTitle?: string;
  pageDescription?: string;
};

type HeadQuery =
  | {
      allContentfulSiteMetadata: {
        edges: {
          node: {
            node_locale: Locale;
            title: string;
            description: string;
            image: {
              fixed: null | {
                src: string;
              };
            };
            contact?: {
              twitter?: string;
            };
          };
        }[];
      };
    }
  | undefined;

export const Head = ({
  authors,
  hideFromSearchEngines,
  pageImage,
  pageTitle,
  pageDescription,
}: HeadProps): ReactElement => {
  const { locale, currentPath } = usePageContext();
  const data: HeadQuery = useStaticQuery(query);
  const localizedData = data?.allContentfulSiteMetadata.edges.find(
    (edge) => edge.node.node_locale === locale
  )?.node;

  const metaTitle = localizedData?.title;
  const title = pageTitle ? `${pageTitle} | ${metaTitle}` : metaTitle;

  const description = pageDescription || localizedData?.description;
  const author = authors?.join(', ') || siteConfig.NAME;
  const image = pageImage || localizedData?.image?.fixed?.src;
  const url = trimFinalSlash(siteConfig.URL) + currentPath;
  const twitter = localizedData?.contact?.twitter?.match(
    CONTACT_REGEX.twitter
  )?.[1];

  return (
    <Helmet defer={false}>
      {/* General tags */}
      <html lang={locale} />
      <title>{title}</title>
      <meta name={'description'} content={description} />
      <meta name={'image'} content={image} />
      <meta name={'author'} content={author} />

      {/* OpenGraph tags */}
      <meta property={'og:url'} content={url} />
      <meta property={'og:locale'} content={locale} />
      <meta property={'og:title'} content={title} />
      <meta property={'og:description'} content={description} />
      <meta property={'og:image'} content={image} />
      <meta property={'og:author'} content={author} />
      <meta property={'og:type'} content={authors ? 'article' : 'website'} />

      {/* Twitter Card tags */}
      <meta name={'twitter:card'} content={'summary_large_image'} />
      <meta name={'twitter:title'} content={title} />
      <meta name={'twitter:description'} content={description} />
      {/* <meta name="twitter:image" content={image} /> */}
      {twitter && <meta name={'twitter:site'} content={'@' + twitter} />}

      {(hideFromSearchEngines ||
        process.env.GATSBY_REQUIRE_AUTH === 'true') && (
        <meta name={'robots'} content={'noindex'} />
      )}
    </Helmet>
  );
};

const query = graphql`
  {
    allContentfulSiteMetadata {
      edges {
        node {
          node_locale
          ...SiteMetadata
        }
      }
    }
  }
`;
