import React, { ReactElement, ReactNode } from 'react';
import NetlifyIdentityContext from 'react-netlify-identity-gotrue';
import { siteConfig } from '../../../siteConfig';
import { trimFinalSlash } from '../../utils/string';

export type IdentityContextProps = {
  children: ReactNode;
};

export const IdentityContext = ({
  children,
}: IdentityContextProps): ReactElement => (
  <NetlifyIdentityContext url={trimFinalSlash(siteConfig.NETLIFY_IDENTITY_URL)}>
    {children}
  </NetlifyIdentityContext>
);
