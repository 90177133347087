import React, {
  createContext,
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react';
import { LOCAL_STORAGE_ITEM as LOCAL_STORAGE_GOOGLE_MAPS } from '../../components/EmbedGoogleMaps/misc';
import { LOCAL_STORAGE_ITEM as LOCAL_STORAGE_SOUNDCLOUD } from '../../components/EmbedSoundCloud/misc';
import { LOCAL_STORAGE_ITEM as LOCAL_STORAGE_SPOTIFY } from '../../components/EmbedSpotify/misc';
import { LOCAL_STORAGE_ITEM as LOCAL_STORAGE_YOUTUBE } from '../../components/EmbedYouTube/misc';
import { isServerSideRendering } from '../../utils/other';

export type ConsentContextProps = {
  children: ReactNode;
};

export type ConsentContext = {
  [key: string]: {
    consent: boolean;
    setConsent: Dispatch<SetStateAction<boolean>>;
  };
};

const Context = createContext<ConsentContext>({});

export const ConsentContext = (props: ConsentContextProps): ReactElement => {
  const isSSR = isServerSideRendering();

  const [googleMaps, setGoogleMaps] = useState(
    isSSR ? false : localStorage.getItem(LOCAL_STORAGE_GOOGLE_MAPS) === 'true'
  );

  const [soundCloud, setSoundCloud] = useState(
    isSSR ? false : localStorage.getItem(LOCAL_STORAGE_SOUNDCLOUD) === 'true'
  );

  const [spotify, setSpotify] = useState(
    isSSR ? false : localStorage.getItem(LOCAL_STORAGE_SPOTIFY) === 'true'
  );

  const [youTube, setYouTube] = useState(
    isSSR ? false : localStorage.getItem(LOCAL_STORAGE_YOUTUBE) === 'true'
  );

  return (
    <Context.Provider
      value={{
        googleMaps: { consent: googleMaps, setConsent: setGoogleMaps },
        soundCloud: { consent: soundCloud, setConsent: setSoundCloud },
        spotify: { consent: spotify, setConsent: setSpotify },
        youTube: { consent: youTube, setConsent: setYouTube },
      }}
      {...props}
    />
  );
};

export const useConsentContext = (): ConsentContext => useContext(Context);
