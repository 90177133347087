const decimalToHex = (decimal: number): string => {
  /* round and convert to a hex value */
  let hex = Math.round(decimal).toString(16);

  /* if only one character, add an initial 0 */
  hex = hex.length === 1 ? '0' + hex : hex;

  return hex;
};

export const rgbToHex = (rgb: number[]): string => {
  /* map over channels */
  const hexes = rgb.map((channel) => decimalToHex(channel));

  /* assemple hex color string */
  const string = '#' + hexes.join('');

  return string;
};
