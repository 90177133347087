import { getOrdinalSuffix } from '../string';

export const localizeDate = (
  locale: string,
  datetime: string,
  condensed?: boolean
): string => {
  /* example output in en-GB from the options below:
  - normal: 31 December 2000 
  - condensed: 31/12/00
  */
  let string = new Date(datetime).toLocaleDateString(locale, {
    year: condensed ? '2-digit' : 'numeric',
    month: condensed ? '2-digit' : 'long',
    day: condensed ? '2-digit' : 'numeric',
    hour: undefined,
    minute: undefined,
    second: undefined,
  });

  /* if invalid date, return early */
  if (string === 'Invalid Date') {
    return string;
  }

  /* 31 December 2000 --> 31st of December 2000 */
  if (locale === 'en-GB' && !condensed) {
    const [day, ...rest] = string.split(' ');
    string = [day + getOrdinalSuffix(parseInt(day)), 'of', ...rest].join(' ');
  }

  return string;
};
