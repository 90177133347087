import React, { ReactElement } from 'react';
import {
  Button as ChakraButton,
  ButtonProps as ChakraButtonProps,
  forwardRef,
} from '@chakra-ui/react';
import { isEmptyNode } from '../../utils/other';
import { BUTTON_STYLES, ButtonVariant } from './misc';

export type ButtonProps = {
  variant?: ButtonVariant;
} & Omit<ChakraButtonProps, 'variant' | 'as'>;

export const Button = forwardRef(
  (
    { children, variant = 'solid', ...rest }: ButtonProps,
    ref
  ): ReactElement | null =>
    isEmptyNode(children) ? null : (
      <ChakraButton
        ref={ref}
        {...(BUTTON_STYLES[variant] || BUTTON_STYLES.solid)}
        {...rest}
      >
        {children}
      </ChakraButton>
    )
);
