import React, { ReactElement } from 'react';
import { ImageFromUrl, ImageFromUrlProps } from '../ImageFromUrl';
import { ImageRaster, ImageRasterProps } from '../ImageRaster';
import { ImageVector, ImageVectorProps } from '../ImageVector';

export type ImageProps = {
  image?:
    | null
    | string
    | {
        gatsbyImageData?: null | ImageRasterProps['image'];
        svg?: null | { content: ImageVectorProps['image'] };
      };
} & Omit<ImageFromUrlProps, 'image'> &
  Omit<ImageRasterProps, 'image'> &
  Omit<ImageVectorProps, 'image'>;

export const Image = ({
  image,
  fill,
  stroke,
  ...rest
}: ImageProps): ReactElement | null =>
  !image ? null : typeof image === 'string' ? (
    <ImageFromUrl image={image} {...rest} />
  ) : image.gatsbyImageData ? (
    <ImageRaster image={image.gatsbyImageData} {...rest} />
  ) : image.svg ? (
    <ImageVector
      image={image.svg.content}
      fill={fill}
      stroke={stroke}
      {...rest}
    />
  ) : null;
