import React, { Fragment, ReactElement } from 'react';
import {
  Box,
  Center,
  Circle,
  Flex,
  HStack,
  Square,
  Stack,
  VisuallyHidden,
} from '@chakra-ui/react';
import { FaArrowUp } from 'react-icons/fa';
import { fluid, OFFSET } from '../../theme/fluid';
import { useLocale } from '../../utils/hooks';
import { Locale } from '../../utils/types';
import { Contact, ContactProps } from '../Contact';
import { ImageVector } from '../ImageVector';
import { Link } from '../Link';
import { Paragraph } from '../Paragraph';
import { SmartLineBreak } from '../SmartLineBreak';
import { CREDITS, TRANS } from './misc';

export type FooterProps = {
  componentLocale?: Locale;
  contact?: ContactProps['data'];
  showOverflow?: boolean;
};

export const Footer = ({
  componentLocale,
  contact,
  showOverflow,
}: FooterProps): ReactElement => {
  const { locale, lang } = useLocale(componentLocale);

  return (
    <Box overflow={showOverflow ? 'visible' : 'hidden'}>
      <Box
        as={'footer'}
        lang={lang}
        backgroundColor={'tealCarnation'}
        color={'white'}
        marginBottom={fluid.fullToFixed}
        marginStart={-OFFSET}
        marginEnd={fluid.halfToHalf}
        paddingStart={fluid.halfToHalfOffset}
        paddingEnd={'0.5em'}
      >
        <Flex
          align={'center'}
          justify={
            contact
              ? { base: 'center', m: 'space-between' }
              : { base: 'center', s: 'space-between' }
          }
          flexDirection={
            contact
              ? { base: 'column', m: 'row' }
              : { base: 'column', s: 'row' }
          }
          paddingX={'0.5em'}
          paddingY={'1em'}
        >
          {contact && (
            <>
              <Box
                maxWidth={{
                  base: '10.5em',
                  s: 'max-content',
                  m: '10.5em',
                  xl: 'auto',
                }}
              >
                <Contact
                  center
                  color={'white'}
                  data={contact}
                  iconSize={`${(
                    1.2 +
                    5 * 0.5 ** Object.keys(contact).length
                  ).toFixed(2)}em`}
                />
              </Box>
              <Box
                backgroundColor={'white'}
                height={{ base: 1, m: '4.5em' }}
                marginX={{ base: 0, m: '1em' }}
                marginY={{ base: '1em', m: 0 }}
                width={{ base: '100%', m: 1, l: 0 }}
              />
            </>
          )}
          <Flex
            flexDirection={{ base: 'column', s: 'row', m: 'column', l: 'row' }}
            justify={'space-between'}
          >
            {CREDITS.map(({ key, href, linkImage, linkText, text }, i) => (
              <Stack
                key={key}
                align={'center'}
                direction={'row'}
                marginStart={i === 0 ? 0 : { base: 0, l: '1.5em' }}
                marginTop={i === 0 ? 0 : { base: '0.5em', s: 0 }}
                textAlign={'left'}
              >
                <Square size={{ base: '3.5em', xs: '2.2em' }} margin={'0.2em'}>
                  <Link
                    href={href}
                    borderColor={'tealCarnation'}
                    borderWidth={2}
                    _hover={{ borderColor: 'white' }}
                  >
                    <ImageVector image={linkImage} fill={'white'} />
                    <VisuallyHidden>{linkText}</VisuallyHidden>
                  </Link>
                </Square>
                <Paragraph
                  fontSize={'0.7em'}
                  margin={0}
                  marginStart={'0.3em !important'}
                  maxWidth={
                    contact
                      ? {
                          'base': '10em',
                          'xs': key === 'xurxe' ? '16em' : '15.5em',
                          '2xl': 'max-content',
                        }
                      : {
                          base: '10em',
                          xs: key === 'xurxe' ? '16em' : '15.5em',
                          m: 'max-content',
                        }
                  }
                >
                  <SmartLineBreak>{text[locale]}</SmartLineBreak>
                </Paragraph>
              </Stack>
            ))}
          </Flex>
          <Box
            backgroundColor={'white'}
            height={{ base: 1, m: '4.5em' }}
            marginX={{ base: 0, m: '1em' }}
            marginY={{ base: '1em', m: 0 }}
            width={{ base: '100%', m: 1, l: 0 }}
          />
          <Box flexShrink={0} flexGrow={0}>
            <Link
              href={'#top'}
              color={'white'}
              display={'inline-block'}
              margin={'-0.25em'}
              marginEnd={'-0.5em'}
              padding={'0.25em'}
              sx={{
                '& .circle': {
                  transitionProperty: 'common',
                  transitionDuration: 'fast',
                  color: 'tealCarnation',
                  backgroundColor: 'white',
                },
                '&:hover .circle': {
                  color: 'white',
                  backgroundColor: 'tealCarnation',
                },
              }}
            >
              <Center>
                <HStack spacing={'0.5em'}>
                  <Box fontSize={'0.7em'}>{TRANS.backToTop[locale]}</Box>
                  <Square
                    className={'square'}
                    margin={'-0.25em'}
                    padding={'0.25em'}
                  >
                    <Circle className={'circle'} borderWidth={2} size={'1.5em'}>
                      <Box margin={-2}>
                        <FaArrowUp />
                      </Box>
                    </Circle>
                  </Square>
                </HStack>
              </Center>
            </Link>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};
