import React, { ReactElement } from 'react';
import { Box } from '@chakra-ui/react';
import { Svg } from '../Svg';

export type ImageVectorProps = {
  image?: string | null;
  textAlternative?: string | null;
  fill?: string;
  stroke?: string;
  width?: string | number;
  maxWidth?: string | number;
};

export const ImageVector = ({
  image,
  textAlternative,
  width,
  maxWidth,
  ...rest
}: ImageVectorProps): ReactElement | null =>
  !image ? null : width ? (
    <Box width={width}>
      <Box width={'100%'}>
        <Svg
          role={textAlternative ? 'img' : 'none'}
          aria-hidden={textAlternative ? undefined : true}
          aria-label={textAlternative || undefined}
          {...rest}
        >
          {image}
        </Svg>
      </Box>
    </Box>
  ) : maxWidth ? (
    <Box maxWidth={maxWidth}>
      <Svg
        role={textAlternative ? 'img' : 'none'}
        aria-hidden={textAlternative ? undefined : true}
        aria-label={textAlternative || undefined}
        {...rest}
      >
        {image}
      </Svg>
    </Box>
  ) : (
    <Box>
      <Svg
        role={textAlternative ? 'img' : 'none'}
        aria-hidden={textAlternative ? undefined : true}
        aria-label={textAlternative || undefined}
        {...rest}
      >
        {image}
      </Svg>
    </Box>
  );
