export const getOrdinalSuffix = (number: number): string => {
  /* negative number --> no ordinal suffix */
  if (number < 0) {
    return '';
  }

  /* special cases: 11th, 12th, 13th */
  if (number === 11 || number === 12 || number === 13) {
    return 'th';
  }

  /* calculate modulo 10 to find out last digit */
  const modulo = number % 10;

  if (modulo === 1) {
    /* 1st, 21st, 31st... */
    return 'st';
  } else if (modulo === 2) {
    /* 2nd, 22nd, 32nd... */
    return 'nd';
  } else if (modulo === 3) {
    /* 3rd, 23rd, 33rd... */
    return 'rd';
  } else {
    /* 0th, 4th, 5th... */
    return 'th';
  }
};
