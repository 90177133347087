export const TRANS = {
  language: {
    en: 'Language',
    fi: 'Kieli',
  },
  english: {
    en: 'English',
    fi: 'Englanti',
  },
  finnish: {
    en: 'Finnish',
    fi: 'Suomi',
  },
};
