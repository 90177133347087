import 'focus-visible';
import { Theme, extendTheme } from '@chakra-ui/react';
import { RecursiveOptional } from '../utils/types';
import { breakpoints } from './breakpoints';
import { colors } from './colors';

const spaceAndSizes = Object.fromEntries(
  [
    0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 5, 6, 7, 8, 9, 10, 12, 14, 16, 20, 24, 28,
    32, 36, 40, 44, 48, 56, 60, 64, 72, 80, 96,
  ].map((number) => [number, `${number}px`])
);

export const customTheme: {
  breakpoints: { [key: string]: string };
} & RecursiveOptional<Theme> = {
  colors: colors.hex,
  breakpoints: breakpoints.withUnit,
  fonts: {
    body: '"Karla", sans-serif',
    heading: '"Karla", sans-serif',
    mono: '"RobotoMono", monospace',
  },
  shadows: {
    outline: `0 0 0 2px white, 0 0 0 4px ${colors.hex.tealCarnation}`,
  },
  space: spaceAndSizes,
  sizes: spaceAndSizes,
  styles: {
    global: {
      '*': {
        'scrollbarColor': `${colors.hex.tealCarnation + 'c1'} transparent`,
        'scrollbarFaceColor': colors.hex.tealCarnation,
        'scrollbarTrackColor': 'transparent',
        'scrollbarWidth': 'thin',
        '&::-webkit-scrollbar': {
          height: '0.6em',
          width: '0.6em',
          backgroundColor: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: colors.hex.tealCarnation + 'c1',
          borderColor: 'white',
          borderRadius: '0.3rem',
          borderStyle: 'solid',
          borderWidth: '0.2rem',
        },
      },
      'html': {
        fontSize: 20,
        scrollBehavior: 'smooth',
      },
      'body': {
        minWidth: 280,
        minHeight: 250,
        color: 'black',
        background: 'white',
        overflowY: 'scroll',
      },
      'button, [role="button"]': {
        cursor: 'auto !important',
      },
    },
  },
};

export const theme = extendTheme(customTheme);
