export const BUTTON_VARIANTS = [
  'solid',
  'outline',
  'ghost',
  'unstyled',
] as const;

export type ButtonVariant = typeof BUTTON_VARIANTS[number];

export const COMMON = {
  borderWidth: 3,
  height: 'auto',
  paddingX: '0.7em',
  paddingY: '0.3em',
};

export const SOLID = {
  background: 'tealCarnation',
  borderColor: 'tealCarnation',
  color: 'white',
};

export const OUTLINE = {
  background: 'white',
  borderColor: 'tealCarnation',
  color: 'tealCarnation',
};

export const GHOST = {
  background: 'white',
  borderColor: 'white',
  color: 'tealCarnation',
};

export const UNSTYLED = {
  background: 'transparent',
  borderColor: 'transparent',
  color: 'tealCarnation',
};

export const ACTIVE = {
  background: 'mintRose',
  borderColor: 'tealCarnation',
  color: 'tealCarnation',
};

export const BUTTON_STYLES = {
  solid: {
    ...COMMON,
    ...SOLID,
    _active: { ...ACTIVE },
    _hover: { ...OUTLINE },
  },
  outline: {
    ...COMMON,
    ...OUTLINE,
    _active: { ...ACTIVE },
    _hover: { ...SOLID },
  },
  ghost: {
    ...COMMON,
    ...GHOST,
    _active: { ...ACTIVE },
    _hover: { ...OUTLINE },
  },
  unstyled: {
    ...COMMON,
    ...UNSTYLED,
    _active: {},
    _hover: {},
  },
};
