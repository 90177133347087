export const TRANS = {
  backToTop: {
    en: 'Back to top',
    fi: 'Takaisin ulos',
  },
  xurxeCredit: {
    en: [
      'Web design and',
      'development by',
      'Xurxe Toivo García',
      '(2021-2022).',
    ],
    fi: [
      'Sivuston suunnittelu',
      'ja kehitys:',
      'Xurxe Toivo García',
      '(2021-2022).',
    ],
  },
  koneCredit: {
    en: ['This project is', 'funded by the', 'Kone Foundation', '(2021-2024).'],
    fi: ['Projektia', 'rahoittaa', 'Koneen Säätiö', '(2021-2024).'],
  },
};

export const LOGO_XURXE = `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<svg width="100%" height="100%" viewBox="0 0 1500 1500" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;fill:#ffffff;">
    <g>
        <path d="M202.625,147.999L465.726,147.999C533.622,148.044 602.501,184.531 638.925,247.999L842.548,600.726L1189.91,151.953C1191.6,149.771 1195.21,147.999 1197.97,147.999L1309.49,147.999C1315.01,147.999 1316.75,151.543 1313.37,155.907L896.627,694.312L1216.23,1248L1306.33,1248C1311.85,1248 1316.33,1252.48 1316.33,1258L1316.33,1338C1316.33,1343.52 1311.85,1348 1306.33,1348L1043.08,1348C980.726,1348 910.726,1318 869.851,1248L698.191,950.56L450.765,1270.31C416.662,1314.61 359.062,1348.26 292.521,1348L202.507,1348C196.988,1348 195.252,1344.46 198.633,1340.1L266.944,1251.95C268.635,1249.77 272.248,1248 275.007,1248L292.521,1248C329.539,1248.11 357.169,1228.11 371.627,1209.2L644.163,856.937L292.521,247.999L202.625,247.999C197.106,247.999 192.625,243.518 192.625,237.999L192.625,157.999C192.625,152.48 197.106,147.999 202.625,147.999ZM408.132,247.999L465.726,247.999C498.96,248.072 533.2,264.707 552.323,297.999L1100.7,1248L1043.09,1248C1005.91,1248.25 973.423,1227.21 956.477,1198.01C813.811,950.837 631.029,634.168 408.132,247.999Z"/>
    </g>
</svg>
`;

export const LOGO_KONE = `<svg id="logosandtypes_com" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150"><style>.st1{fill:#ff8278}</style><path d="M0 0h150v150H0V0z" fill="none" id="Layer_3"/><g id="logosandtypes_com"><path class="st1" d="M75.7 12.2c-33.9 0-61.4 27.5-61.4 61.4S41.8 135 75.7 135s61.4-27.5 61.4-61.4-27.5-61.4-61.4-61.4zm0 117c-30.6 0-55.6-25-55.6-55.6S45.1 18 75.7 18c30.8 0 55.6 25 55.6 55.6s-25 55.6-55.6 55.6z"/><path class="st1" d="M81.3 75.6c-.9-1.1-2-1.6-3.4-1.6H65.2c-.4 0-.5.4-.4.7l20.3 25.9C86.2 102 88 103 90 103h11.8c.4 0 .5-.4.4-.7L81.3 75.6zM89.6 54l10.7-9.4c.2-.2 0-.5-.2-.5H84.6c-.2 0-.4.2-.4.4l4.7 9.4c.2.3.6.3.7.1zM115.2 73.6c-5.8-1.1-10.3-5.6-11.4-11.4 0-.2-.4-.2-.4 0-1.1 5.8-5.6 10.3-11.4 11.4-.2 0-.2.4 0 .4 5.8 1.1 10.3 5.6 11.4 11.4 0 .2.4.2.4 0 1.1-5.8 5.6-10.3 11.4-11.4.3 0 .3-.4 0-.4zM59.4 44.1H46.2c-.4 0-.5.2-.5.5v28.6c0 .4.2.5.5.5h13.2c.4 0 .5-.2.5-.5V44.6c0-.2-.1-.5-.5-.5zM46.2 88.3c-.2-.4-.7-.2-.7.2v13.9c0 .4.2.5.5.5h10.5c.4 0 .5-.4.4-.7L46.2 88.3z"/></g></svg>`;

export const CREDITS = [
  {
    key: 'kone',
    href: 'https://koneensaatio.fi/',
    linkImage: LOGO_KONE,
    linkText: 'koneensaatio.fi',
    text: TRANS.koneCredit,
  },
  {
    key: 'xurxe',
    href: 'https://xurxe.com/',
    linkImage: LOGO_XURXE,
    linkText: 'xurxe.com',
    text: TRANS.xurxeCredit,
  },
];

export const MOCK_FOOTER = {
  contact: {
    order: [
      'Email',
      'Website',
      'Phone',
      'Postal address',
      'Facebook',
      'Twitter',
      'Instagram',
      'YouTube',
      'Twitch',
      'LinkedIn',
      'Medium',
      'ResearchGate',
    ],
    email: 'example@example.com',
    facebook: 'https://www.facebook.com/example',
    instagram: 'https://www.instagram.com/example',
    linkedIn: 'https://www.linkedin.com/example',
    medium: 'https://medium.com/example',
    phone: '00385000000',
    researchGate: 'https://www.researchgate.net/example',
    twitter: 'https://www.twitter.com/example',
    website: 'https://example.com',
    youTube: 'https://www.youtube.com/example',
  },
};
