import { Locale } from '../types';

export const localizeList = (list: string[], locale: Locale): string => {
  if (list.length < 2) {
    return list.join();
  }

  const end = list.pop();
  const beginning = list.join(', ');
  const middle =
    locale === 'en' && list.length > 1
      ? ', and '
      : locale === 'en'
      ? ' and '
      : locale === 'fi'
      ? ' ja '
      : ', ';

  return beginning + middle + end;
};
