import React, { ReactElement } from 'react';
import { Box, Image, ImageProps } from '@chakra-ui/react';

export type ImageFromUrlProps = {
  image?: string;
  textAlternative?: string | null;
  width?: string | number;
  maxWidth?: string | number;
} & Omit<ImageProps, 'src' | 'alt' | 'width'>;

export const ImageFromUrl = ({
  image,
  textAlternative,
  width,
  maxWidth,
  ...rest
}: ImageFromUrlProps): ReactElement | null =>
  !image ? null : width ? (
    <Box width={width}>
      <Image {...rest} alt={textAlternative || ''} src={image} width={'100%'} />
    </Box>
  ) : maxWidth ? (
    <Image {...rest} alt={textAlternative || ''} src={image} width={maxWidth} />
  ) : (
    <Image {...rest} alt={textAlternative || ''} src={image} />
  );
