import { Locale } from '../../utils/types';

export const TRANS = {
  primary: {
    en: 'Primary',
    fi: 'Ensisijainen',
  },
  frontpage: {
    en: 'Frontpage',
    fi: 'Etusivu',
  },
  showMenu: {
    en: 'Show menu',
    fi: 'Näytä valikko',
  },
  showSubmenu: {
    en: 'Show submenu',
    fi: 'Näytä alivalikko',
  },
};

export const MOCK_NAVIGATION = {
  node_locale: 'en' as Locale,
  subpages: [
    {
      title: 'Standard',
      slug: 'standard',
      subpages: [
        {
          title: 'Standard 2',
          slug: 'standard2',
          subpages: [
            {
              title: 'Standard 2',
              slug: 'standard3',
            },
          ],
        },
        {
          title: 'Collection',
          slug: 'collection',
        },
      ],
    },
    {
      title: 'Collection 2',
      slug: 'collection2',
    },
  ],
};
