import React, { ReactElement, ReactNode } from 'react';
import { navigate, PageProps } from 'gatsby';
import { useIdentityContext } from 'react-netlify-identity-gotrue';
import { isServerSideRendering } from '../../utils/other';
import { Cover } from '../Cover';

export type AuthProps = {
  children: ReactNode;
  location: PageProps['location'];
};

export const Auth = ({
  children,
  location,
}: AuthProps): ReactElement | null => {
  const isSSR = isServerSideRendering();
  const requireAuth = process.env.GATSBY_REQUIRE_AUTH === 'true';
  const identity = useIdentityContext();

  if (isSSR) {
    return (
      <>
        <Cover fadeIn key={location.pathname} />
        {children}
      </>
    );
  }

  /* if auth is required and for some reason the identity module doesn't work (for example, if you didn't turn on Identity on Netlify), render the cover */
  if (requireAuth && !identity) {
    return <Cover fadeIn key={location.pathname} />;
  }

  const { pathname, hash } = location;
  const { user, urlToken } = identity;

  /* if auth is required, the user is not logged in, we're not already in the login page, and the url has an invite token (aka, if the user got there by clicking on an link that was in an email sent by Netlify Identity) --> redirect to the login page */
  if (
    requireAuth &&
    !user &&
    !pathname.startsWith('/login') &&
    (hash.startsWith('#invite_token') || urlToken?.type === 'invite')
  ) {
    return <>{navigate('/login')}</>;
  }

  /* if auth is required, the user is not logged in, and the path is NOT the login nor the 404 page --> render the cover */
  if (
    requireAuth &&
    !user &&
    !pathname.startsWith('/login') &&
    !pathname.startsWith('/404')
  ) {
    return <Cover fadeIn key={location.pathname} />;
  }

  /* if auth is NOT required, and the user goes to the login page --> redirect to the frontpage  */
  if (!requireAuth && pathname.startsWith('/login')) {
    return <>{navigate('/')}</>;
  }

  /* otherwise (for the most part: if auth is not required, or if the user is logged in) --> render as usual */
  return <>{children}</>;
};
