import React, {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
} from 'react';

export type IsStorybookContextProps = {
  children: ReactNode;
  value: boolean;
};

const Context = createContext(true);

export const IsStorybookContext = (
  props: IsStorybookContextProps
): ReactElement => <Context.Provider {...props} />;

export const useIsStorybookContext = (): boolean => useContext(Context);
