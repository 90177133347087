import React, { ReactElement } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { ContactProps } from '../Contact';
import { Footer, FooterProps } from '.';

type FooterQuery = {
  contentfulSiteMetadata: {
    contact: ContactProps['data'];
  };
};

export const FooterWithQuery = ({
  showOverflow,
}: Pick<FooterProps, 'showOverflow'>): ReactElement => {
  const data: FooterQuery = useStaticQuery(query);
  return (
    <Footer
      contact={data.contentfulSiteMetadata.contact}
      showOverflow={showOverflow}
    />
  );
};

const query = graphql`
  {
    contentfulSiteMetadata {
      contact {
        ... on ContentfulContact {
          ...Contact
        }
      }
    }
  }
`;
