import React from 'react';
import { Link } from '../Link';

export const ASPECT_RATIOS = ['1:1', '4:3', '16:9'] as const;

export type AspectRatio = typeof ASPECT_RATIOS[number];

export const MAP = {
  '1:1': 1,
  '4:3': 4 / 3,
  '16:9': 16 / 9,
};

export const REGEX =
  /(?:(?:https?:)?\/\/)?(?:(?:www|m)\.)?(?:(?:youtube(?:-nocookie)?\.com|youtu.be))(?:\/(?:[\w\-]+\?v=|embed\/|v\/)?)(?!channel)([\w\-]+)(?:&list=)?([\w\-]+)?/;

export const LOCAL_STORAGE_ITEM = 'x-consent-youTube';

export const MOCK_EMBED = {
  single: {
    url: 'https://www.youtube.com/watch?v=3jWRrafhO7M',
    title: 'Aaa',
  },
  multiple: {
    url: 'https://www.youtube.com/watch?v=3jWRrafhO7M&list=PLYyJCobshLZlTxiQvR12noxc38n0zXv0G',
    title: 'Aaa',
  },
};

export const TRANS = {
  consentTitle: {
    en: 'We need your consent to show you this YouTube player.',
    fi: 'Tarvitsemme suostumuksesi näyttääksemme tämän YouTube-soittimen.',
  },
  consentParagraph: {
    en: (
      <>
        YouTube might collect personal data and track your viewing behavior.
        We’ll only load the player if you consent to their use of cookies and
        similar technologies as described in their{' '}
        <Link href={'https://policies.google.com/privacy?hl=en'}>
          privacy policy
        </Link>
        .
      </>
    ),
    fi: (
      <>
        YouTube saattaa kerätä henkilötietoja ja seurata
        katselukäyttäytymistäsi. Lataamme soittimen vain, jos hyväksyt
        evästeiden ja vastaavien tekniikoiden käyttöön heidän{' '}
        <Link href={'https://policies.google.com/privacy?hl=fi'}>
          tietosuojakäytännössään
        </Link>{' '}
        kuvatulla tavalla.
      </>
    ),
  },
  consentButton: {
    en: 'Consent',
    fi: 'Hyväksy',
  },
};
