import React, { ReactElement, useMemo } from 'react';
import { Square, VisuallyHidden } from '@chakra-ui/react';
import { IconType } from 'react-icons/lib';
import { useLocale } from '../../utils/hooks';
import { prettifyUrl } from '../../utils/string';
import { Locale } from '../../utils/types';
import { Link } from '../Link';
import { SmartWrap } from '../SmartWrap';
import { CONTACT_ICONS, CONTACT_NAMES, CONTACT_REGEX, TRANS } from './misc';

export type ContactProps = {
  componentLocale?: Locale;
  center?: boolean;
  color?: string;
  data?: {
    order?: string[];
    email?: string;
    facebook?: string;
    instagram?: string;
    linkedIn?: string;
    medium?: string;
    phone?: string;
    researchGate?: string;
    twitter?: string;
    website?: string;
    youTube?: string;
  };
  iconSize?: string | number;
};

type ContactItem = {
  key: string;
  href: string;
  icon: IconType;
  label: string;
};

export const Contact = ({
  componentLocale,
  center,
  color = 'tealCarnation',
  data,
  iconSize,
}: ContactProps): ReactElement | null => {
  const { locale, lang } = useLocale(componentLocale);

  const items = useMemo(() => {
    if (!data) {
      return [];
    }
    const { order, ...rest } = data;
    const mappedOrder = order?.map((item) => CONTACT_NAMES[item]);

    return Object.entries(rest)
      .sort(
        (a, b) =>
          (mappedOrder?.indexOf(a[0]) || 0) - (mappedOrder?.indexOf(b[0]) || 0)
      )
      .map(
        ([key, value]) =>
          key &&
          value &&
          CONTACT_ICONS[key] &&
          TRANS[key] && {
            key: key,
            label:
              TRANS[key][locale] +
              ': ' +
              (value.match(CONTACT_REGEX[key])?.[1]
                ? (key === 'instagram' || key === 'twitter' ? '@' : '') +
                  value.match(CONTACT_REGEX[key])?.[1]
                : prettifyUrl(value)),
            icon: CONTACT_ICONS[key],
            href:
              key === 'email'
                ? 'mailto:' + value
                : key === 'phone'
                ? 'tel:' + value
                : value,
          }
      )
      .filter((item) => item) as ContactItem[];
  }, [locale, data]);

  return items.length === 0 ? null : (
    <SmartWrap
      lang={lang}
      align={center ? 'center' : undefined}
      justify={center ? 'center' : undefined}
      spacing={2}
    >
      {items.map((item, i) => (
        <Link
          key={`contact-${i}`}
          href={item.href}
          color={color}
          borderWidth={2}
          borderColor={'transparent'}
          _hover={{ borderColor: color }}
        >
          <Square fontSize={iconSize || '1.7em'} padding={'0.2em'}>
            <item.icon />
            <VisuallyHidden>{item.label}</VisuallyHidden>
          </Square>
        </Link>
      ))}
    </SmartWrap>
  );
};
