import { usePrefersReducedMotion } from '@chakra-ui/react';
import { MotionConfigProps } from 'framer-motion';

export const useReducedMotion = (
  reducedMotion: MotionConfigProps['reducedMotion']
): boolean => {
  const userPrefersReducedMotion = usePrefersReducedMotion();

  return (
    reducedMotion === 'always' ||
    (reducedMotion === 'user' && userPrefersReducedMotion)
  );
};
