import React from 'react';
import { Link } from '../Link';

export const SIZES = ['small', 'medium', 'large'] as const;

export type Size = typeof SIZES[number];

export const MAP = {
  small: 80,
  medium: 235,
  large: 390,
};

export const REGEX =
  /(?:(?:https?:)?\/\/)?(?:(?:open)\.)?(?:(?:spotify\.com))(?:\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-\/]+)([\w\-]+)?/;

export const LOCAL_STORAGE_ITEM = 'x-consent-spotify';

export const MOCK_EMBED = {
  single: {
    url: 'https://open.spotify.com/track/1UdZAe29sFZXWTSpQFjzGY',
    title: 'Aaa',
  },
  multiple: {
    url: 'https://open.spotify.com/album/61Wd24B5BzxB1BbJ2SfEvR',
    title: 'Aaa',
  },
};

export const TRANS = {
  consentTitle: {
    en: 'We need your consent to show you this Spotify player.',
    fi: 'Tarvitsemme suostumuksesi näyttääksemme tämän Spotify-soittimen.',
  },
  consentParagraph: {
    en: (
      <>
        Spotify might collect personal data and track your listening behavior.
        We’ll only load the player if you consent to their use of cookies and
        similar technologies as described in their{' '}
        <Link href={'https://www.spotify.com/uk/legal/privacy-policy/'}>
          privacy policy
        </Link>
        .
      </>
    ),
    fi: (
      <>
        Spotify saattaa kerätä henkilötietoja ja seurata
        kuuntelukäyttäytymistäsi. Lataamme soittimen vain, jos hyväksyt
        evästeiden ja vastaavien tekniikoiden käyttöön heidän{' '}
        <Link href={'https://www.spotify.com/fi/legal/privacy-policy/'}>
          tietosuojakäytännössään
        </Link>{' '}
        kuvatulla tavalla.
      </>
    ),
  },
  consentButton: {
    en: 'Consent',
    fi: 'Hyväksy',
  },
};
