import { breakpoints } from './breakpoints';

const S = breakpoints.withoutUnit.s;
const L = breakpoints.withoutUnit.l;

const FULL = 20;
const FULL_L_PX = L * 0.2;
const FULL_L_VW = 20;

const HALF = FULL / 2;
const HALF_L_PX = FULL_L_PX / 2;
const HALF_L_VW = FULL_L_VW / 2;

export const OFFSET = 500;

export const fluid = {
  fullToFull: {
    base: `${FULL}px`,
    s: `calc(${FULL}px + ${FULL_L_PX - FULL} * (100vw - ${S}px) / ${L - S})`,
    l: `${FULL_L_VW}vw`,
  },
  fullToFullNegative: {
    base: `-${FULL}px`,
    s: `calc(-1 * (${FULL}px + ${FULL_L_PX - FULL} * (100vw - ${S}px) / ${
      L - S
    }))`,
    l: `-${FULL_L_VW}vw`,
  },
  fullToFixed: {
    base: `${FULL}px`,
    s: `calc(${FULL}px + ${60 - FULL} * (100vw - ${S}px) / ${L - S})`,
    l: `${60}px`,
  },
  halfToHalf: {
    base: `${HALF}px`,
    s: `calc(${HALF}px + ${HALF_L_PX - HALF} * (100vw - ${S}px) / ${L - S})`,
    l: `${HALF_L_VW}vw`,
  },
  halfToHalfOffset: {
    base: `calc(${OFFSET}px + ${HALF}px)`,
    s: `calc(${OFFSET}px + ${HALF}px + ${
      HALF_L_PX - HALF
    } * (100vw - ${S}px) / ${L - S})`,
    l: `calc(${OFFSET}px + ${HALF_L_VW}vw)`,
  },
  zeroToHalf: {
    base: `${0}px`,
    s: `calc(${0}px + ${HALF_L_PX - 0} * (100vw - ${S}px) / ${L - S})`,
    l: `${HALF_L_VW}vw`,
  },
};

export const fluidSpace = {
  base: `${20}px`,
  s: `calc(${20}px + ${240 - 20} * (100vw - ${600}px) / ${1200 - 600})`,
  l: '20vw',
};

export const fluidSpaceToFixed = {
  base: `${20}px`,
  s: `calc(${20}px + ${60 - 20} * (100vw - ${600}px) / ${1200 - 600})`,
  l: `${60}px`,
};

export const fluidSpaceNegative = {
  base: `-${20}px`,
  s: `calc(-1 * (${20}px + ${240 - 20} * (100vw - ${600}px) / ${1200 - 600}))`,
  l: '-20vw',
};

export const fluidSpaceSmall = {
  base: `${10}px`,
  s: `calc(${10}px + ${120 - 10} * (100vw - ${600}px) / ${1200 - 600})`,
  l: '10vw',
};

export const fluidSpaceSmallOffset = {
  base: `calc(500px + ${10}px)`,
  s: `calc(500px + ${10}px + ${120 - 10} * (100vw - ${600}px) / ${1200 - 600})`,
  l: 'calc(500px + 10vw)',
};

export const fluidSpaceSmaller = {
  base: `${0}px`,
  s: `calc(${0}px + ${120 - 0} * (100vw - ${600}px) / ${1200 - 600})`,
  l: '10vw',
};

export const fluidSpaceVertical = {
  base: `${20}px`,
  s: `calc(${20}px + ${60 - 20} * (100vw - ${600}px) / ${900 - 600})`,
  m: '60px',
};
