import React, { ReactElement, ReactNode } from 'react';
import { Global } from '@emotion/react';
import { ChakraProvider } from '@chakra-ui/react';
import { theme } from '../../theme';
import { global } from '../../theme/global';

export type ThemeContextProps = {
  children: ReactNode;
};

export const ThemeContext = ({ children }: ThemeContextProps): ReactElement => (
  <ChakraProvider theme={theme}>
    <Global styles={global} />
    {children}
  </ChakraProvider>
);
