const UNIT = 'px';

const initial = [
  {
    key: 'xs',
    name: 'Extra-small',
    width: 300,
    type: 'mobile',
  },
  {
    key: 's',
    name: 'Small',
    width: 600,
    type: 'tablet',
  },
  {
    key: 'm',
    name: 'Medium',
    width: 900,
    type: 'tablet',
  },
  {
    key: 'l',
    name: 'Large',
    width: 1200,
    type: 'desktop',
  },
  {
    key: 'xl',
    name: 'Extra-large',
    width: 1500,
    type: 'desktop',
  },
  {
    key: '2xl',
    name: 'Extra-extra-large',
    width: 1800,
    type: 'desktop',
  },
];

const withUnit = Object.fromEntries(
  initial.map(({ key, width }) => [key, width + UNIT])
);

const withoutUnit = Object.fromEntries(
  initial.map(({ key, width }) => [key, width])
);

const array = initial.map(({ name, width, type }) => ({
  name: name,
  styles: {
    width: width + UNIT,
    height: '100%',
  },
  type: type,
}));

export const breakpoints = {
  withUnit,
  withoutUnit,
  array,
};
