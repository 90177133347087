import { hsluvToHex } from '../utils/color';

const initial = [
  {
    key: 'tealCarnation',
    name: 'Teal Carnation',
    hsluv: [200, 100, 49.5],
  },
  {
    key: 'aquaOrchid',
    name: 'Aqua Orchid',
    hsluv: [170, 100, 90],
  },
  {
    key: 'mintRose',
    name: 'Mint Rose',
    hsluv: [170, 100, 96],
  },
  {
    key: 'grayViolet',
    name: 'Gray Violet',
    hsluv: [320, 5, 43],
  },
  {
    key: 'pinkTrillium',
    name: 'Pink Trillium',
    hsluv: [320, 100, 65],
  },
  {
    key: 'purpleLavender',
    name: 'Purple Lavender',
    hsluv: [290, 100, 94],
  },
];

const hsluv = Object.fromEntries(initial.map(({ key, hsluv }) => [key, hsluv]));

const hex = Object.fromEntries(
  initial.map(({ key, hsluv }) => [key, hsluvToHex(hsluv)])
);

const array = initial.map(({ name, hsluv }) => ({
  title: name,
  color: hsluvToHex(hsluv),
}));

export const colors = {
  hsluv,
  hex,
  array,
};
