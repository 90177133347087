import React, {
  ReactElement,
  ReactNode,
  useLayoutEffect,
  useState,
} from 'react';
import type { PageProps } from 'gatsby';
import { MotionConfigProps } from 'framer-motion';
import { PageContext, PageContextData } from '../../context/PageContext';
import { GenericQuery } from '../../templates/Generic';
import { BlogCollectionQuery } from '../../templates/BlogCollection';
import { BlogPostQuery } from '../../templates/BlogPost';
import { EventCollectionQuery } from '../../templates/EventCollection';
import { EventPostQuery } from '../../templates/EventPost';
import { useReducedMotion } from '../../utils/hooks';
import { truncate } from '../../utils/string';
import { Auth } from '../Auth';
import { FooterWithQuery } from '../Footer/withQuery';
import { Head } from '../Head';
import { Layout } from '../Layout';
import { NavigationWithQuery } from '../Navigation/withQuery';
import { richTextAsString } from '../RichTextAsParagraph';

export type AppProps = {
  children: ReactNode;
  reducedMotion?: MotionConfigProps['reducedMotion'];
} & Omit<PageProps, 'children'>;

export const App = ({
  children,
  data,
  location,
  pageContext,
  reducedMotion,
}: AppProps): ReactElement => {
  const template = (pageContext as PageContextData).template;
  const locale = (pageContext as PageContextData).locale;

  const title =
    template === 'Generic'
      ? (data as GenericQuery)?.contentfulPageGeneric?.title
      : template === 'BlogCollection'
      ? (data as BlogCollectionQuery)?.contentfulPageBlogCollection?.title
      : template === 'BlogPost'
      ? (data as BlogPostQuery)?.contentfulPageBlogPost?.title
      : template === 'EventCollection'
      ? (data as EventCollectionQuery)?.contentfulPageEventCollection?.title
      : template === 'EventPost'
      ? (data as EventPostQuery)?.contentfulPageEventPost?.title
      : undefined;

  const content =
    template === 'Generic'
      ? (data as GenericQuery)?.contentfulPageGeneric?.content
      : template === 'BlogPost'
      ? (data as BlogPostQuery)?.contentfulPageBlogPost?.content
      : template === 'EventPost'
      ? (data as EventPostQuery)?.contentfulPageEventPost?.content
      : undefined;

  const description = truncate(richTextAsString(content?.raw), 256);

  const image =
    template === 'BlogPost'
      ? (data as BlogPostQuery)?.contentfulPageBlogPost?.image?.fixed?.src
      : template === 'EventPost'
      ? (data as EventPostQuery)?.contentfulPageEventPost?.image?.fixed?.src
      : undefined;

  const authors =
    template === 'BlogPost'
      ? (data as BlogPostQuery)?.contentfulPageBlogPost?.authors
          ?.map((author) => author.name)
          .concat(
            (data as BlogPostQuery)?.contentfulPageBlogPost?.authorsOther || []
          )
      : undefined;

  const hideNavigation = !template;
  const hideFooter = !template;
  const hideCover = template !== 'Frontpage';

  const [newPageContext, setNewPageContext] =
    useState<Partial<PageContextData>>(pageContext);
  const reduceMotion = useReducedMotion(reducedMotion);

  useLayoutEffect(() => {
    /* if motion is reduced, update page context immediately */
    if (reduceMotion) {
      setNewPageContext(pageContext);
      return;
    }
    /* otherwise, wait long enough for layout animations to run (see <Layout> template) */
    const timer = setTimeout(() => {
      setNewPageContext(pageContext);
    }, 500);
    return () => clearTimeout(timer);
  }, [reduceMotion, pageContext]);

  return (
    <PageContext value={newPageContext as PageContextData}>
      <Head
        pageTitle={title}
        pageDescription={description}
        pageImage={image}
        authors={authors}
      />
      <Auth location={location}>
        <Layout
          navigation={<NavigationWithQuery />}
          footer={<FooterWithQuery showOverflow={!reduceMotion} />}
          hideNavigation={hideNavigation}
          hideFooter={hideFooter}
          hideCover={hideCover}
          keyNavigation={locale}
          keyFooter={locale}
          keyMain={location.pathname}
          reducedMotion={reducedMotion}
        >
          {children}
        </Layout>
      </Auth>
    </PageContext>
  );
};
