import React, { ReactElement, ReactNode } from 'react';
import { Flex, Spacer } from '@chakra-ui/react';
import {
  motion,
  AnimatePresence,
  LayoutGroup,
  MotionConfigProps,
} from 'framer-motion';
import { useReducedMotion } from '../../utils/hooks';
import { Cover } from '../Cover';
import { Main } from '../Main';

export type LayoutProps = {
  children: ReactNode;
  navigation: ReactElement;
  footer: ReactElement;
  hideNavigation?: boolean;
  hideFooter?: boolean;
  hideCover?: boolean;
  keyNavigation?: string;
  keyFooter?: string;
  keyMain?: string;
  reducedMotion?: MotionConfigProps['reducedMotion'];
};

export const Layout = ({
  children,
  navigation,
  footer,
  hideNavigation,
  hideCover,
  hideFooter,
  keyNavigation,
  keyMain,
  keyFooter,
  reducedMotion = 'user',
}: LayoutProps): ReactElement => {
  const reduceMotion = useReducedMotion(reducedMotion);

  return (
    <Flex flexDirection={'column'} minHeight={'100vh'}>
      <div id={'top'} />
      {reduceMotion ? (
        <>
          {hideNavigation ? null : navigation}
          {hideCover ? null : <Cover fadeIn />}
          <Main>{children}</Main>
          {hideFooter ? null : (
            <>
              <Spacer />
              {footer}
            </>
          )}
        </>
      ) : (
        <LayoutGroup>
          <AnimatePresence exitBeforeEnter>
            {hideNavigation ? null : (
              <motion.div
                key={`navigation-${keyNavigation}`}
                initial={'hidden'}
                animate={'shown'}
                exit={'hidden'}
                variants={{
                  hidden: {
                    x: '-100%',
                    transition: {
                      ease: 'easeInOut',
                      duration: 0.5,
                    },
                  },
                  shown: {
                    x: 0,
                    transition: {
                      type: 'spring',
                      damping: 10,
                      mass: 1,
                      stiffness: 50,
                    },
                  },
                }}
                style={{
                  position: 'fixed',
                  top: 0,
                  right: 0,
                  left: 0,
                  zIndex: 1000,
                }}
              >
                {navigation}
              </motion.div>
            )}
          </AnimatePresence>
          <AnimatePresence exitBeforeEnter>
            <motion.div
              key={`main-${keyMain}`}
              initial={'hidden'}
              animate={'shown'}
              exit={'hidden'}
              variants={{
                hidden: {
                  opacity: 0,
                  transition: {
                    ease: 'easeInOut',
                    duration: 0.25,
                  },
                },
                shown: {
                  opacity: 1,
                  transition: {
                    ease: 'easeInOut',
                    duration: 0.5,
                    delay: 0.5,
                  },
                },
              }}
              layout={'position'}
              layoutDependency={keyMain}
            >
              {hideCover ? null : <Cover />}
              <Main>{children}</Main>
            </motion.div>
          </AnimatePresence>
          <AnimatePresence exitBeforeEnter>
            {hideFooter ? null : (
              <>
                <Spacer />
                <motion.div
                  key={`footer-${keyFooter}`}
                  layout={'position'}
                  style={{ overflowX: 'hidden' }}
                >
                  <motion.div
                    initial={'hidden'}
                    animate={'shown'}
                    exit={'hidden'}
                    variants={{
                      hidden: {
                        x: '-100%',
                        transition: { ease: 'easeInOut', duration: 0.5 },
                      },
                      shown: {
                        x: 0,
                        transition: {
                          type: 'spring',
                          damping: 10,
                          mass: 1,
                          stiffness: 50,
                          delay: 0.5,
                        },
                      },
                    }}
                  >
                    {footer}
                  </motion.div>
                </motion.div>
              </>
            )}
          </AnimatePresence>
        </LayoutGroup>
      )}
    </Flex>
  );
};
