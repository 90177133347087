import React from 'react';
import type { GatsbyBrowser } from 'gatsby';
import { ConsentContext } from './src/context/ConsentContext';
import { IdentityContext } from './src/context/IdentityContext';
import { IsStorybookContext } from './src/context/IsStorybookContext';
import { ThemeContext } from './src/context/ThemeContext';
import { App } from './src/components/App';

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
  element,
}) => (
  <ConsentContext>
    <IdentityContext>
      <IsStorybookContext value={false}>
        <ThemeContext>{element}</ThemeContext>
      </IsStorybookContext>
    </IdentityContext>
  </ConsentContext>
);

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
  props,
}) => (
  <App {...props} reducedMotion={'always'}>
    {element}
  </App>
);
