import React from 'react';
import { Link } from '../Link';

export const LOCAL_STORAGE_ITEM = 'x-consent-googleMaps';

export const MOCK_EMBED = {
  url: 'https://www.google.com/maps/place/University+of+Turku/@60.4563,22.2829198,17z/data=!3m1!4b1!4m5!3m4!1s0x468c76e7b0d67ded:0xf2066fe5769c9801!8m2!3d60.4562974!4d22.2851138',
  title: 'Aaa',
};

export const REGEX =
  /(?:(?:https?:)?\/\/)?(?:(?:www)\.)?(?:(?:google\.\w+\/))maps\/place\/([\w\+-]+)\/@([+-]?[0-9]+\.?[0-9]*),([+-]?[0-9]+\.?[0-9]*)(?:.+)?/;

export const TRANS = {
  consentTitle: {
    en: 'We need your consent to show you Google Maps.',
    fi: 'Tarvitsemme suostumuksesi näyttääksemme Google Mapsin.',
  },
  consentParagraph: {
    en: (
      <>
        Google Maps might collect personal data and track your behavior. We’ll
        only load the map if you consent to their use of cookies and similar
        technologies as described in their{' '}
        <Link href={'https://www.google.com/help/terms_maps/'}>
          privacy policy
        </Link>
        .
      </>
    ),
    fi: (
      <>
        Google Maps saattaa kerätä henkilötietoja ja seurata käyttäytymistäsi.
        Lataamme kartan vain, jos hyväksyt evästeiden ja vastaavien tekniikoiden
        käyttöön heidän{' '}
        <Link href={'https://www.google.com/help/terms_maps/?hl=fi'}>
          tietosuojakäytännössään
        </Link>{' '}
        kuvatulla tavalla.
      </>
    ),
  },
  consentButton: {
    en: 'Consent',
    fi: 'Hyväksy',
  },
};
