import React, { Children, ReactElement } from 'react';
import { Flex, FlexProps } from '@chakra-ui/react';
import { positiveOrZero } from '../../utils/number';

export type SmartWrapProps = {
  spacing?: number | string;
  spacingX?: number | string;
  spacingY?: number | string;
} & FlexProps;

export const SmartWrap = ({
  children,
  spacing,
  spacingX,
  spacingY,
  ...rest
}: SmartWrapProps): ReactElement | null => {
  if (!children) {
    return null;
  }

  const array = Children.toArray(children);

  if (array.length === 1) {
    return (
      <Flex as={'span'} {...rest}>
        {children}
      </Flex>
    );
  }

  const half = Math.ceil(array.length / 2);

  spacing = positiveOrZero(spacing);
  spacingX = spacingX === undefined ? spacing : positiveOrZero(spacingX);
  spacingY = spacingY === undefined ? spacing : positiveOrZero(spacingY);

  return (
    <Flex
      as={'span'}
      flexWrap={'wrap'}
      marginX={'-' + spacingX.toString()}
      marginY={'-' + spacingY.toString()}
      {...rest}
    >
      <Flex
        as={'span'}
        align={'flex-start'}
        marginX={spacingX}
        marginY={spacingY}
      >
        <SmartWrap spacingX={spacingX} spacingY={spacingY} {...rest}>
          {array.slice(0, half)}
        </SmartWrap>
      </Flex>
      <Flex
        as={'span'}
        align={'flex-start'}
        marginX={spacingX}
        marginY={spacingY}
      >
        <SmartWrap spacingX={spacingX} spacingY={spacingY} {...rest}>
          {array.slice(half)}
        </SmartWrap>
      </Flex>
    </Flex>
  );
};
