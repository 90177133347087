import React, { ReactElement, ReactNode } from 'react';
import { Text, TextProps } from '@chakra-ui/react';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { INLINES, BLOCKS, Inline, Block } from '@contentful/rich-text-types';
import { NO_BREAK_SPACE } from '../../utils/string';
import { RichTextData } from '../RichText';

const addSeparation = (node: ReactNode): string => {
  const string = node?.toString();

  return !string ||
    string.endsWith(',') ||
    string.endsWith(']') ||
    string.endsWith(' ')
    ? ''
    : string.endsWith('.') || string.endsWith('!') || string.endsWith('?')
    ? ' '
    : '. ';
};

const renderInline = (children: ReactNode): string => {
  const string = !children
    ? ''
    : Array.isArray(children)
    ? children
        .flat()
        .filter((child) => child)
        .join('')
    : children.toString();

  return string;
};

const renderBlock = (children: ReactNode): string => {
  const string = !children
    ? ''
    : Array.isArray(children)
    ? children
        .flat()
        .filter((child) => child)
        .join('')
    : children.toString();

  return !string ? '' : string + addSeparation(string);
};

type RichTextAsParagraphOptions = {
  renderText?: {
    (text: string): string;
  };
  renderMark?: {
    bold?: (text: ReactNode) => string;
    italic?: (text: ReactNode) => string;
    code?: (text: ReactNode) => string;
  };
  renderNode?: {
    [Key in INLINES | BLOCKS]?: (
      node: Inline | Block,
      children: ReactNode
    ) => string;
  };
};

const options: RichTextAsParagraphOptions = {
  renderText: (text: string) => text.split(NO_BREAK_SPACE).join(''),

  renderMark: {
    bold: (children) => (!children ? '' : children.toString()),
    italic: (children) => (!children ? '' : children.toString()),
    code: (children) => (!children ? '' : children.toString()),
  },
  renderNode: {
    [INLINES.HYPERLINK]: (_, children) => renderInline(children),
    [INLINES.ENTRY_HYPERLINK]: (_, children) => renderInline(children),
    [BLOCKS.PARAGRAPH]: (_, children) => renderBlock(children),
    [BLOCKS.HEADING_2]: (_, children) => renderBlock(children),
    [BLOCKS.HEADING_3]: (_, children) => renderBlock(children),
    [BLOCKS.HEADING_4]: (_, children) => renderBlock(children),
    [BLOCKS.LIST_ITEM]: (_, children) => renderInline(children),
    [BLOCKS.OL_LIST]: (_, children) => renderBlock(children),
    [BLOCKS.UL_LIST]: (_, children) => renderBlock(children),
    [BLOCKS.QUOTE]: (_, children) => renderBlock(children),
    [BLOCKS.HR]: () => '',
    [INLINES.EMBEDDED_ENTRY]: () => '',
    [BLOCKS.EMBEDDED_ENTRY]: () => '',
  },
};

export type RichTextAsParagraphProps = {
  data?: RichTextData;
} & TextProps;

export const RichTextAsParagraph = ({
  data,
  ...rest
}: RichTextAsParagraphProps): ReactElement | null =>
  !data?.raw ? null : (
    <Text {...rest}>
      {renderRichText(
        { raw: data.raw, references: data?.references || [] },
        options
      )}
    </Text>
  );

export const richTextAsString = (raw?: string): string =>
  !raw
    ? ''
    : renderInline(renderRichText({ raw: raw, references: [] }, options)) || '';
