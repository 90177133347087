import { getOrdinalSuffix, NO_BREAK_SPACE } from '../string';

export const localizeDateAndTime = (
  locale: string,
  datetime: string,
  condensed?: boolean
): string => {
  /* example output in en-GB from the options below:
  - normal: 31 December 20 at 20:00 
  - condensed: 31/12/2000 20:00
  */
  let string = new Date(datetime).toLocaleDateString(locale, {
    year: condensed ? '2-digit' : 'numeric',
    month: condensed ? '2-digit' : 'long',
    day: condensed ? '2-digit' : 'numeric',
    hour: condensed ? '2-digit' : 'numeric',
    minute: condensed ? '2-digit' : 'numeric',
    second: undefined,
    hourCycle: 'h23',
  });

  /* if invalid date, return early */
  if (string === 'Invalid Date') {
    return string;
  }

  /* 31 December 2000 at 20:00--> 31st of December 2000 at 20:00 */
  if (locale === 'en-GB' && !condensed) {
    const [day, ...rest] = string.split(' ');
    string = [day + getOrdinalSuffix(parseInt(day)), 'of', ...rest].join(' ');
  }

  /* fix for weird bug in some versions of Safari */
  if (locale === 'fi') {
    string = string.split(' Hlo ').join(' klo ');
  }

  /* if condensed, prevent separation of date and time */
  if (locale === 'en-GB' && condensed) {
    string = string.split(', ').join(NO_BREAK_SPACE);
  }
  if (locale === 'fi' && condensed) {
    string = string.split(' klo ').join(' ').split(' ').join(NO_BREAK_SPACE);
  }

  return string;
};
