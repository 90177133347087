import React, { ReactElement } from 'react';
import { Center } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { fluid } from '../../theme/fluid';
import { Logo } from '../Logo';

export type CoverProps = {
  fadeIn?: boolean;
};

export const Cover = ({ fadeIn }: CoverProps): ReactElement => (
  <motion.header
    initial={fadeIn ? 'hidden' : 'shown'}
    animate={'shown'}
    exit={'hidden'}
    variants={{
      hidden: {
        opacity: 0,
      },
      shown: {
        opacity: 1,
        transition: {
          ease: 'easeInOut',
          duration: 0.5,
        },
      },
    }}
  >
    <Center
      height={'100vh'}
      width={'100%'}
      paddingX={fluid.fullToFull}
      paddingTop={40}
    >
      <Logo />
    </Center>
  </motion.header>
);
