export const ZERO_WIDTH_SPACE = '\u200B';

export const NO_BREAK_SPACE = '\u00A0';

export const ZERO_WIDTH_NO_BREAK_SPACE = '\uFEFF';

export const EN_SPACE = '\u2002';

export const EM_SPACE = '\u2003';

export const SOFT_HYPHEN = '\u00AD';

export const EN_DASH = '\u2013';

export const EM_DASH = '\u2014';

export const ELLIPSIS = '…';
